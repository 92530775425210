<template>
  <MDBCardBody class="language-selector card--height">
    <MDBCardTitle class="text-start font-35 mb-3">
      <div v-html="$t('login.title')"></div>
    </MDBCardTitle>
    <MDBCardText class="pb-0">
      <h1 class="pb-2">{{ $t("login.languageSelect.label") }}</h1>
      <div class="select-desc">
        {{ $t("login.languageSelect.description") }}
      </div>
      <div
          class="d-flex flex-row justify-content-start align-items-center mt-4"
      >
        <img
            :src="flagMap[tenant]"
            class="language-selector-flag"
            alt="flag"
        />
        <div>
          <div v-for="locale in availableLocales"
               :key="locale"
                class="lang-btn cursor--pointer"
                @click="setLanguage(locale)"
          >

            {{ $t("login.languageSelect.start."+ locale.replace('-','')) }}
          </div>
        </div>
      </div>
      <!-- Back to Login Button -->
      <div class="mt-5 text-center">
        <button class="btn btn-primary" @click="$emit('backToLogin')">
          {{ $t("login.buttons.back") }}
        </button>
      </div>
    </MDBCardText>
  </MDBCardBody>
</template>

<script setup>
import {
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdb-vue-ui-kit";
import { defineProps } from "vue";
import { useI18n } from "vue-i18n"; // Import useI18n to interact with i18n

// Props
defineProps(["availableLocales", "tenant"]);

const { locale } = useI18n(); // Get the reactive locale property from i18n

const flagMap = {
  "GB": require("@/assets/img/flags/gb.svg"),
  "FR": require("@/assets/img/flags/fr.svg"),
  "DE": require("@/assets/img/flags/de.svg"),
  "NL": require("@/assets/img/flags/nl.svg"),
  "IT": require("@/assets/img/flags/it.svg"),
  "CH": require("@/assets/img/flags/ch.svg"),
  "PT": require("@/assets/img/flags/pt.svg"),
  "BE": require("@/assets/img/flags/be.svg"),
  "ES": require("@/assets/img/flags/es.svg"),
  "AT": require("@/assets/img/flags/at.svg"),
};

const setLanguage = (newLocale) => {
  locale.value = newLocale; // Update the i18n locale
  localStorage.setItem("currentLocale", newLocale)
}
</script>

<style scoped>
.lang-btn {
  margin-left: 10px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}
</style>
<style lang='scss' scoped>
@import '@/styles/variables';
@import '@/styles/auth';
.language-selector {
  h1 {
    font-size: 20px;
    padding-bottom: 0 !important;
  }
  .select-desc {
  }
}
.language-selector-flag {
  max-width: 100px;
}
</style>