<template>
  <MDBCardBody class="card--height">
    <MDBCardTitle class="text-start font-35 mb-3">
      <div v-html="$t('login.title')"></div>
    </MDBCardTitle>
    <MDBCardText class="pb-0">
      <p class="font-16" v-html="$t('login.description')"></p>
      <p class="font-16" v-html="$t('login.info')"></p>
    </MDBCardText>
    <ButtonMediumVue
        className="btn btn-danger btn-lg d-flex justify-content-center align-items-center font-12 btn-position ripple-surface"
        div-class-name="text-white fw-bold margin-reset font-16"
        property1="default"
        :text="$t('login.buttons.action')"
        @click="handleSsoRedirection"
        :disableState="loading"
    />
    <!-- Trigger Language Selector -->
    <button
        v-if="showLanguageButton"
        class="btn btn-primary mt-3"
        @click="$emit('toggleLanguageSelector')"
    >
      {{ $t("login.buttons.changeLanguage") }}
    </button>
  </MDBCardBody>
</template>

<script setup>
import { computed, defineProps } from "vue";
import {
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdb-vue-ui-kit";
import ButtonMediumVue from "@/components/General/Buttons/ButtonMedium.vue";
// Props
const props = defineProps({
  loading: Boolean,
  handleSsoRedirection: Function,
  tenantConfig: Object,
  availableLocales: { type: Array, required: true },
});

// Computed property to check if language button should be displayed
const showLanguageButton = computed(() => props.availableLocales.length > 1);
</script>

<style scoped>
.btn-primary {
  margin-top: 20px;
}
</style>
