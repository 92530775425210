<template>
  <div v-if="show">
    <!-- Show Language Selector -->
    <LanguageSelect
        v-if="languageSelectionAvailable && languageSelector"
        :available-locales="availableLocales"
        :tenant="tenant"
        @backToLogin="toggleLanguageSelector"
    />

    <!-- Show Default Login -->
    <component
        v-else
        :is="currentView"
        :handle-sso-redirection="handleSSORedirection"
        :tenant-config="props.tenantConfig"
        :loading="props.loading"
        :available-locales="availableLocales"
        @toggleLanguageSelector="toggleLanguageSelector"
    />
  </div>
</template>

<script setup>
import { defineProps, ref, computed, watchEffect } from "vue";
import { getBaseURL, countryToLocaleMap } from "@/utils/tenantUtils";
import { useStore } from "vuex";
import LanguageSelect from "@/components/LanguageSelect.vue";

import DefaultLoginContent from "./login-variants/default.vue";
// if custom views for login required
//import CHLoginContent from "./login-variants/tenant/ch.vue";

// Vuex Store
const store = useStore();

// Props from Auth.vue
const props = defineProps(["tenantConfig", "loading"]);

// Tenant Configuration
const tenant = ref(false);
const show = ref(false);
const languageSelector = ref(false); // Controls toggling between views
const baseURL = ref("");

// Handle SSO redirection
const handleSSORedirection = () => {
  //alert(`${baseURL.value}/b2c?tenant=${tenant.value}`)
  window.location.href = `${baseURL.value}/b2c?tenant=${tenant.value}`;
};

// Watch effect for tenant and locale updates
watchEffect(() => {
  tenant.value = store.getters.tenantId;
  baseURL.value = getBaseURL(tenant.value);
  show.value = true;
});

// Available locales for the tenant
const availableLocales = computed(() => {
  return countryToLocaleMap[tenant.value]?.available || [];
});

// Determine if language selection is available
const languageSelectionAvailable = computed(() => {
  return availableLocales.value.length > 1;
});

// Current view (default login or tenant-specific login)
const currentView = computed(() => {
  switch (tenant.value) {
    //case "CH":
    //  return CHLoginContent;
    default:
      return DefaultLoginContent;
  }
});

// Toggle between default login and language selector
const toggleLanguageSelector = () => {
  languageSelector.value = !languageSelector.value;
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
@import "@/styles/auth";
</style>
